#loading-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  justify-content: center;
  align-items: center;
}
#loading-overlay .loader {
  color: #fff;
}